<template>
  <v-container>
    <v-row>
      <v-col class="px-0">
        <v-toolbar
          width="100%"
        >
          <v-row align="center">
            <v-col
              cols="6"
              lg="4"
            >
              <v-select
                id="siteSelectionDropdown"
                v-model="selectedSite"
                :items="siteData"
                item-text="name"
                label="Select site"
                class="pt-5"
                :loading="siteData.length === 0 && showAlert === false"
                return-object
                @change="onItemSelected"
              />
            </v-col>
            <v-spacer />
            <v-tooltip
              v-if="showTemperatureOnToolbar"
              bottom
              open-delay="1000"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  :text="activeLayer !== 'temperature'"
                  dark
                  v-bind="attrs"
                  :loading="loadingtemperature"
                  v-on="on"
                  @click="toggleTemperatureLayer"
                >
                  <v-icon>
                    mdi-thermometer-lines
                  </v-icon>
                </v-btn>
              </template>
              <span>View Temperature Layer</span>
            </v-tooltip>
            <v-tooltip
              v-if="siteElements.length > 0 || equipmentElements.length > 0"
              bottom
              open-delay="1000"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-menu
                    v-model="showTableSearch"
                    :close-on-content-click="false"
                    offset-y
                  >
                    <template v-slot:activator="props">
                      <v-btn
                        color="primary"
                        text
                        dark
                        v-bind="props.attrs"
                        v-on="props.on"
                      >
                        <v-icon>
                          mdi-table-search
                        </v-icon>
                      </v-btn>
                    </template>
                    <div
                      class="d-flex justify-center flex-wrap pb-7"
                      style="width: 500px; background: white; padding-bottom: 5px;"
                    >
                      <v-data-table
                        class="row-pointer"
                        :headers="headers"
                        :items="siteElements.length > 0 ? siteElements : equipmentElements"
                        :search="tableSearchValue"
                        :fixed-header="true"
                        @click:row="(item) => mapclick(item)"
                      >
                        <template v-slot:[`body.prepend`]>
                          <tr class="no-pointer">
                            <td>
                              <v-text-field
                                v-model="tableFieldName"
                                type="text"
                                class="pt-4"
                                outlined
                                dense
                                label="filter..."
                              />
                            </td>
                            <td>
                              <v-text-field
                                v-model="tableFieldStatus"
                                type="number"
                                class="pt-4"
                                outlined
                                dense
                              />
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                  </v-menu>
                </div>
              </template>
              <span>Find Equipment/Site By Table</span>
            </v-tooltip>
          </v-row>
        </v-toolbar>
        <v-alert
          v-if="showAlert"
          :type="alertType"
          class="mt-3 mb-0"
        >
          {{ alertMessage }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-show="showMap">
      <div
        id="sites_map"
        style="width: 100%; height: 700px; z-index: 1;"
      />
    </v-row>
    <v-row v-if="!showMap">
      <v-skeleton-loader
        v-if="!imageSrc && !siteNotFound"
        width="100%"
        min-height="80vh"
        type="image"
      />
      <v-img
        id="site-map-img"
        contain
        :src="imageSrc"
      >
        <div
          class="d-flex flex-column"
          style="position: absolute; top: 10px; right: 10px; z-index: 2"
        >
          <v-btn
            v-if="siteAbove"
            id="siteLayerUpArrowButton"
            color="grey lighten-1"
            text
            @click="mapclick(siteAbove)"
          >
            <v-icon
              large
              @mouseover="event => {addMouseOver(event.target, $refs.svgOverlay, siteAbove.name)}"
              @mouseleave="showTooltip = false"
            >
              mdi-arrow-up-bold
            </v-icon>
          </v-btn>
          <v-btn
            v-if="siteBelow"
            id="siteLayerDownArrowButton"
            color="grey lighten-1"
            text
            @click="mapclick(siteBelow)"
          >
            <v-icon
              large
              @mouseover="event => {addMouseOver(event.target, $refs.svgOverlay, siteBelow.name)}"
              @mouseleave="showTooltip = false"
            >
              mdi-arrow-down-bold
            </v-icon>
          </v-btn>
        </div>
        <div
          id="svg-overlay"
          ref="svgOverlay"
          style="position: absolute; width: 100%"
        />
        <div
          v-if="showTooltip"
          id="tooltip"
          class="d-flex justify-center align-center pa-2"
          :style="`position: absolute; top: ${tooltipY}px; left: ${tooltipX}px;
            min-width: 100px; min-height: 50px; background: white; border-radius: 15px;
            opacity: .9;
          `"
        >
          <span>
            {{ tooltipText }}
          </span>
        </div>
      </v-img>
    </v-row>
    <v-row>
      <v-dialog
        v-model="showErrorDialog"
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            Error
          </v-card-title>
          <v-card-text>
            Unable to find layout for given site. Please try again later.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="dismissErrorDialog()"
            >
              Okay
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog
      v-model="showToolDialog"
      persistent
      width="500px"
    >
      <setpoints-card
        v-if="showSetpoints"
        @close="()=>{
          showSetpoints = false
          showToolDialog = false
        }"
      />
    </v-dialog>
    <v-speed-dial
      v-if="!showMap"
      v-model="fab"
      bottom
      right
      fixed
    >
      <template v-slot:activator>
        <v-tooltip
          left
          open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue darken-2"
              dark
              v-bind="attrs"
              fab
              v-on="on"
            >
              <v-icon
                v-if="fab"
                large
              >
                mdi-close
              </v-icon>
              <v-icon
                v-else
                large
              >
                mdi-cog
              </v-icon>
            </v-btn>
          </template>
          <span>Site Functions</span>
        </v-tooltip>
      </template>
      <v-tooltip
        left
        open-delay="1000"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            color="red darken-2"
            v-bind="attrs"
            @click="navigateToAlarms"
            v-on="on"
          >
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </template>
        <span>Alarms</span>
      </v-tooltip>
      <v-tooltip
        left
        open-delay="1000"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            color="teal lighten-2"
            v-bind="attrs"
            @click="$router.push({ path: `/site/${rootSiteName}/${rootSiteId}/diagrams/` })"
            v-on="on"
          >
            <v-icon>mdi-file-table-box-multiple-outline</v-icon>
          </v-btn>
        </template>
        <span>Diagrams</span>
      </v-tooltip>
    </v-speed-dial>
  </v-container>
</template>

<script>
  import sitesApi from '@/api/sites.js'
  import common from '@/api/common.js'
  import store from '@/store'
  import sitesHolder from '@/util/sites-holder.js'
  import { siteLayerEnum } from '@/util/enums'

  export default {
    name: 'SiteView',

    data: () => ({
      showErrorDialog: false,
      siteData: [],
      selectedSite: null,
      imageSrc: '',
      dataPath: '',
      showAlert: false,
      alertType: 'error',
      alertMessage: '',
      alertMessageStandard: 'We are unable to access your Site data at this time. Please try again later.',
      alertMessageNoSiteFound: 'There are no Sites currently being monitored by Lumii. Please contact us for more information if you are interested in learning more about this service.',
      showMap: false,

      showToolDialog: false,
      showSetpoints: false,
      fab: false,

      tooltipX: 0,
      tooltipY: 0,
      tooltipText: '',
      showTooltip: false,

      rootSiteId: -1,
      rootSiteName: '',
      equipmentElements: [],
      siteElements: [],

      overlayPath: '',
      activeLayer: '',
      showTemperatureOnToolbar: false,
      loadingtemperature: false,

      pennyPoller: null,

      showTableSearch: false,
      tableSearchValue: '',
      tableFieldName: '',
      tableFieldStatus: '',

      siteAbove: null,
      siteBelow: null,

      siteNotFound: false,

    }),

    computed: {
      headers () {
        return [
          {
            text: 'Name',
            align: 'left',
            value: 'name',
            filter: value => {
              if (!this.tableFieldName) return true
              return (value + '').toLowerCase().includes(this.tableFieldName.toLowerCase())
            },
          },
          {
            text: 'Status',
            value: 'status',
            filter: value => {
              if (!this.tableFieldStatus) return true
              return value === parseInt(this.tableFieldStatus)
            },
          },
        ]
      },
    },
    mounted () {
      this.siteData = sitesHolder.getSites()
      if (this.siteData.length === 0) {
        this.getSitesForUser()
      } else {
        if (!this.$route.params.id) {
          this.createMap()
        }
      }

      // $nextTick only runs after view is rendered
      // need view to be rendered to apply overlay svg to the DOM
      this.$nextTick(function () {
        this.setCurrentSite()
      })
    },

    beforeDestroy () {
      console.log('closing poller')
      clearInterval(this.pennyPoller)
    },

    methods: {
      dismissErrorDialog: function () {
        this.showErrorDialog = false
      },

      getSitesForUser: function () {
        this.siteLocationSelectors = []

        sitesApi.getSitesForUser()
          .then(response => {
            console.log(response)
            this.siteData = response.data.map(siteResponse =>
              siteResponse.site,
            )
            // Sets site v-select to current root as each page is independent
            // TODO: just get the first thing after Site or something

            sitesHolder.addSites(this.siteData)
            if (!this.$route.params.id) {
              this.createMap()
            }
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getSitesForUser()
            } else {
              this.showAlert = true
              this.alertType = 'error'
              this.alertMessage = this.alertMessageStandard
            }
          })
      },

      setCurrentSite: function () {
        if (this.$route.params.id) {
          sitesApi.getSiteById(this.$route.params.id)
            .then(response => {
              console.log(response)
              this.setImageSrc(response.data.site.assetPath)
              if (response.data.site.assetPath === 'none') {
                this.showAlert = true
                this.alertType = 'info'
                this.alertMessage = this.alertMessageNoSiteFound
                this.siteNotFound = true
              }
              this.overlayPath = response.data.site.overlayPath
              this.setOverlaySrc(response.data.site.overlayPath)
              this.dataPath = response.data.site.dataPath
              if (response.data.siteLevels.aboveSiteId) this.siteAbove = { id: 'site_' + response.data.siteLevels.aboveSiteId, name: response.data.siteLevels.aboveSiteName, status: response.data.siteLevels.aboveSiteStatus }
              if (response.data.siteLevels.belowSiteId) this.siteBelow = { id: 'site_' + response.data.siteLevels.belowSiteId, name: response.data.siteLevels.belowSiteName, status: response.data.siteLevels.belowSiteStatus }

              // TODO set up a more robust way of figuring out exactly which tools to display
              if (response.data.configList.length > 0) {
              }

              this.rootSiteId = response.data.site.rootSiteId
              this.rootSiteName = response.data.site.rootSiteName
              this.equipmentElements = response.data.siteSvgElements.equipmentElement
              this.siteElements = response.data.siteSvgElements.siteElements

              response.data.siteLayers.forEach(layer => {
                if (layer.siteLayerEnumId === 1) {
                  this.showTemperatureOnToolbar = true
                }
              })
            })
            .catch(async error => {
              console.log(error)
              if (await common.handleBadCall(error, this.$router) === true) {
                this.setCurrentSite()
              }
            })
        }
      },

      onItemSelected: function (item) {
        this.selectedSite = item
        // reset breadcrumbs in store when a site is selected
        store.set('app/addBreadCrumb', { title: 'Site', to: '/site/' })
        this.$router.push({
          path: '',
          params: {
            site: item.name,
            id: item.id,
          },
        }).catch(() => {
          // for navigation redundancy error makes sure current site is still part of breadcrumbs
          store.set('app/addBreadCrumb', { title: item.name, to: `/site/${item.id}/${item.name}` })
        })
      },

      /**
       * Database is queried for what sites a person can see
       * That query will return either a path (as the image is currently in the assets folder) or a
       * URL to where the image is hosted
       * Subsequent calls
      */
      setImageSrc: function (src) {
        try {
          if (src.includes('campus')) {
            this.imageSrc = require('../assets/' + src)
          }
          // TODO: Have an else branch to handle a URI to the asset
        } catch (error) {
          console.log(error)
          this.showErrorDialog = true
          return ''
        }
      },

      clearMap: function () {
        this.imageSrc = ''
        this.overlayPath = ''
      },

      /**
       * Database is queried for what sites a person can see
       * That query will return either a path (as the image is currently in the assets folder) or a
       * URL to where the image is hosted
      */
      setOverlaySrc: function (src) {
        if (src.includes('overlay')) {
          fetch(require('../assets/' + src))
            .then(response => response.text())
            .then(text => {
              var svgString = text
              var svgOverlay = document.getElementById('svg-overlay')
              svgOverlay.innerHTML = svgString

              var allGTags = document.getElementsByTagName('g')
              allGTags.forEach(element => {
                element.addEventListener('click', () => this.mapclick(element))
                element.addEventListener('mouseover', e => { this.addMouseOver(element, svgOverlay, this.getSiteOrEquipment(element.attributes.id.value).name) })
                element.addEventListener('mouseleave', e => {
                  this.showTooltip = false
                })
              })
            })
        }
        // TODO: Have an else branch to handle a URI to the asset
      },

      addMouseOver: function (element, svgOverlay, dataName) {
        const overlayRect = svgOverlay.getBoundingClientRect()
        const elementRect = element.getBoundingClientRect()
        if (!dataName) dataName = 'Unable to retrieve data name'

        // display tooltip on left or right of element based on what side of the screen the left of the element is
        // position of tooltipX for right half is calculated based on the average character width guess * #characters for data-name + margins or the min tooltip width
        // 5px x offset for all instances so tooltip is not on top of element
        if (elementRect.left > overlayRect.left + overlayRect.width / 2) {
          if (dataName.length * 8 < 100) {
            this.tooltipX = (elementRect.left - overlayRect.left) - 105
          } else {
            this.tooltipX = (elementRect.left - overlayRect.left) - 5 - 16 - dataName.length * 8
          }
        } else {
          this.tooltipX = (elementRect.left - overlayRect.left) + elementRect.width + 5
        }
        this.tooltipY = elementRect.top - overlayRect.top + elementRect.height / 2 - 25
        this.tooltipText = dataName
        this.showTooltip = true
      },

      // ID is in the corresponding SVG
      // If the ID is prepended with eqp_ you are clicking into a piece of equipment and sends you to the
      // Equipment Page
      // Otherwise, the ID will inform the next SVG to load
      mapclick: function (element) {
        let dataElement
        if (element.status) {
          dataElement = element
        } else {
          dataElement = this.getSiteOrEquipment(element.id)
        }

        if (dataElement.status === 1) {
          if (element.id.includes('eqp_')) {
            var equipment = element.id.substring(4) // eqp_ = 4 chars
            const errorState = -1
            // TODO clean up url params
            this.$router.push({
              name: 'Equipment',
              params: {
                site: this.$route.params.site,
                rootSiteId: this.rootSiteId,
                equipmentId: equipment || errorState,
              },
            }).catch(() => {})
          } else {
            this.$router.push({
              path: '',
              params: {
                site: dataElement.name,
                id: element.id.substring(5),
              },
            }).catch(error => {
              console.log(error)
              // for navigation redundancy error makes sure current site is still part of breadcrumbs
              store.set('app/addBreadCrumb', { title: dataElement.name, to: `/site/${dataElement.name}/${element.id.split(',')[0].substring(5)}` })
            })
          }
        } else {
          this.showErrorDialog = true
        }
      },

      getSiteOrEquipment: function (id) {
        let item = {}
        if (id.includes('eqp_')) {
          for (let i = 0; i < this.equipmentElements.length; i++) {
            if (this.equipmentElements[i].id === id) {
              item = this.equipmentElements[i]
              break
            }
          }
        } else if (id.includes('site_')) {
          for (let i = 0; i < this.siteElements.length; i++) {
            if (this.siteElements[i].id === id) {
              item = this.siteElements[i]
              break
            }
          }
        }
        return item
      },

      createMap: function () {
        this.showMap = true
        const L = window.L
        const layer = new L.StamenTileLayer('terrain')
        const map = new L.Map('sites_map', {
          center: new L.LatLng(38.1, -93),
          zoom: 5,
          maxZoom: 15,
        })
        map.addLayer(layer)
        this.siteData.forEach(site => {
          const marker = L.marker([site.geolocation.lat, site.geolocation.lng])
          marker
            .addTo(map)
            .bindPopup(site.name)
            .on('mouseover', (e) => {
              marker.openPopup()
            })
            .on('mouseout', (e) => {
              marker.closePopup()
            })
            .on('click', (e) => {
              this.onItemSelected(site)
            })
        })
        setTimeout(function () {
          map.invalidateSize(true)
        }, 100)
      },

      openSetpoints: function () {
        this.showToolDialog = true
        this.showSetpoints = true
      },
      navigateToAlarms: function () {
        this.$router.push({ path: `/site/${this.rootSiteName}/${this.rootSiteId}/alarms/` })
      },

      toggleTemperatureLayer: function () {
        if (this.activeLayer === 'temperature') {
          this.activeLayer = ''
          this.showAlert = false
          this.setOverlaySrc(this.overlayPath)
          clearInterval(this.pennyPoller)
        } else {
          this.activeLayer = 'temperature'
          this.setOverlaySrc(this.overlayPath.split('.')[0] + '-temperature.svg')
          const siteLayerId = Object.keys(siteLayerEnum).find(key => siteLayerEnum[key] === 'temperature')
          this.getLayerData(siteLayerId, 'temperature')

          this.pennyPoller = setInterval(function () {
            this.getLayerData(siteLayerId, 'temperature')
          }.bind(this), 60000)
        }
      },

      getLayerData: function (siteLayerId, layer) {
        this['loading' + layer] = true
        sitesApi.getDataForSiteLayer(this.$route.params.id, siteLayerId)
          .then(response => {
            console.log(response)
            response.data.forEach(equipmentItem => {
              if (layer === 'temperature' && Object.keys(equipmentItem.equipmentLayerData[0]).length === 1) {
                Object.values(equipmentItem.equipmentLayerData[0]).forEach(value => {
                  document.getElementById(equipmentItem.equipmentSvgId + '_text').textContent = value + '°F'
                })
              } else if (layer === 'temperature' && Object.keys(equipmentItem.equipmentLayerData[0]).length > 1) {
                Object.keys(equipmentItem.equipmentLayerData[0]).forEach(key => {
                  document.getElementById(key + '_text').textContent = equipmentItem.equipmentLayerData[0][key] + '°F'
                })
              }
            })
            this.showAlert = false
            this['loading' + layer] = false
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getLayerData(siteLayerId, layer)
            } else {
              this.showAlert = true
              this.alertType = 'error'
              this.alertMessage = `We are unable to access your ${layer} data at this time. Please try again later.`
              this['loading' + layer] = false
            }
          })
      },
    },
  }
</script>
<style lang="scss">
g {
  &:hover{
    cursor: pointer;
  }
}
.v-skeleton-loader__image.v-skeleton-loader__bone { height: 100%; }
.row-pointer>.v-data-table__wrapper>table>tbody>tr :hover {
cursor: pointer;
}
.row-pointer>.v-data-table__wrapper>table>tbody>.no-pointer :hover {
cursor: default;
}
.row-pointer>.v-data-table__wrapper>table>tbody>.no-pointer>td .v-input__slot :hover {
cursor: text;
}
</style>
